/**
 * External Dependencies
 */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Configuration, RunApi } from "autotab";

/**
 * Internal Components
 */
import SelectedNote from "./selectednote";
import UnselectedNote from "./unselectednote";
import Button from "../../shared/button";

/**
 * Internal Dependencies
 * - Styles
 * - Redux Actions
 * - Services and Utilities
 */
import "./framenotes.css";
import { useSelector, useDispatch } from "react-redux";
import {
  upsertUserStats,
  setCurrentState,
  setVisit,
  setNotification,
} from "../../../redux/actions";
import { generateId, getCurrentDate } from "../../../scripts/apiService";
import DarkButton from "../../shared/darkbutton";

/**
 * FrameNotes component for displaying and managing notes.
 * @param {object} props - The component props.
 * @param {string} props.rootClassName - The root class name for styling.
 * @returns {JSX.Element} The FrameNotes component.
 */
const FrameNotes = ({ rootClassName }) => {
  /**
   * @dispatch - Redux dispatch function to send actions to the store.
   * @currentVisitId - Current visit ID from the global state.
   * @currentNoteId - Current note ID from the global state.
   * @userData - User data from the global state.
   * @buttonIconSrc - State for button icon source.
   * @setButtonIconSrc - Function to update button icon source.
   * @noteListRef - Reference to the note list DOM element.
   * @currentVisit - Current visit based on the current visit ID.
   * @notes - Notes of the current visit.
   * @visitTypes - Visit types from the global state.
   * @isInitialLoad - State to track if it's the initial load.
   * @setIsInitialLoad - Function to update the initial load state.
   * @prevVisitTypeId - Previous visit type id
   */
  const dispatch = useDispatch();
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const currentNoteId = useSelector(
    (state) => state.globalData.currentState.currentNoteId,
  );
  const userData = useSelector((state) => state.globalData.userData);
  const [buttonIconSrc, setButtonIconSrc] = useState("/icons/copy.svg");
  const noteListRef = useRef(null);
  const currentVisit =
    userData.visits?.find((visit) => visit._id === currentVisitId) || null;
  const notes = currentVisit?.notes || [];
  const visitTypes = useSelector(
    (state) => state.globalData.userData.visitTypes,
  );
  const prevVisitTypeId = useRef(currentVisit.visitTypeId);
  const [notificationDisplayed, setNotificationDisplayed] = useState(false);

  useEffect(() => {
    if (!currentVisit?.visitTypeUptoDate && !notificationDisplayed) {
      dispatch(
        setNotification({
          name: "Template Updated",
          description:
            "Click regenerate to get the latest template. Until then some AI features may not work.",
          status: "warning",
          duration: 10000,
          isClosable: true,
        }),
      );
      setNotificationDisplayed(true);
    }
  }, [currentVisit]);

  useEffect(() => {
    /**
     * Sends notification if the visitType was not found
     */
    if (
      !visitTypes.some(
        (visitType) => visitType._id === currentVisit.visitTypeId,
      )
    ) {
      dispatch(
        setNotification({
          name: "Template Not Found",
          description:
            "The template does not exist. Some functionality on this page may not work.",
          status: "warning",
          duration: 10000,
          isClosable: true,
        }),
      );
    }
  }, [visitTypes]);

  /**
   * Reloads notes based on the current visit type.
   */
  const reloadNotes = async () => {
    const visitType = visitTypes.find(
      (type) => type._id === currentVisit.visitTypeId,
    );

    if (visitType) {
      const isTranscriptInsufficient =
        currentVisit.visitTranscript.split(" ").length < 10 &&
        currentVisit.visitAdditionalPatientContext == "";

      const newNotes = visitType.noteTypes.map((noteType) => ({
        _id: generateId(),
        noteName: noteType.noteTypeName,
        noteBody: isTranscriptInsufficient
          ? "[not enough data; insufficient transcript]"
          : "",
        noteTypeId: noteType._id,
        noteFirstTimeGenerated: !isTranscriptInsufficient,
        noteIsVisible: true,
      }));

      dispatch(
        setVisit(currentVisitId, { notes: newNotes, visitTypeUptoDate: true }),
      );
    }
  };

  useEffect(() => {
    /**
     * Reloads notes on visit type change.
     */
    if (prevVisitTypeId.current !== currentVisit.visitTypeId) {
      reloadNotes();
      prevVisitTypeId.current = currentVisit.visitTypeId;
    }
  }, [currentVisit.visitTypeId]);

  /**
   * Handles note click event.
   * @param {string} noteId - The ID of the clicked note.
   */
  const handleRegenerateClick = () => {
    reloadNotes();
  };

  /**
   * Handles note click event.
   * @param {string} noteId - The ID of the clicked note.
   */
  const handleNoteClick = async (noteId) => {
    dispatch(setCurrentState({ currentNoteId: noteId }));
  };

  /**
   * Handles copy all button click event.
   */
  const handleCopyAllClick = async () => {
    dispatch(
      setVisit(currentVisitId, {
        visitCopyMetric: (currentVisit.visitCopyMetric || 0) + 1,
      }),
    );

    dispatch(
      upsertUserStats({
        userStatsDate: getCurrentDate(),
        userStatsCopyMetric: 1,
      }),
    );

    try {
      const visitType = visitTypes.find(
        (type) => type._id === currentVisit.visitTypeId,
      );
      const clipboardText = notes
        .filter(
          (note) => note.noteIsVisible === undefined || note.noteIsVisible,
        )
        .map((note) => {
          if (visitType?.visitTypeBodyType === "TEMPLATE") {
            dispatch(
              setNotification({
                name: "Sync to Epic",
                description:
                  "Inside an Epic note, use Ctrl + Alt + V on Windows or Cmd + Option + V on macOS.",
                status: "info",
                duration: 7000,
                isClosable: true,
              }),
            );
            return `${note.noteBody}`;
          } else {
            return `${note.noteName.toUpperCase()}:\n${note.noteBody}`;
          }
        })
        .join("\n\n");
      if (clipboardText !== "") {
        await navigator.clipboard.writeText(
          clipboardText +
            "\n\nDisclaimer: This note was created with the help of Halo Medical Solutions, Inc. It is possible that typographical errors may have occurred. Any questions about content or clarifications should be directed to the medical provider.",
        );
      }
      setButtonIconSrc("/icons/checkmark.svg");
      setTimeout(() => {
        setButtonIconSrc("/icons/copy.svg");
      }, 2000);
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  };

  const runClient = new RunApi(
    new Configuration({
      apiKey: "sk-SeIaLYgh2resNsgXLoGcutymEf970ueHGZRWiODHqQMEdJnZ-tiGq",
    }),
  );

  const handleSendToEMRClickVijayDaniel = async () => {
    console.log(currentVisit.visitTypeId);
    const visitType = visitTypes.find(
      (type) => type._id === currentVisit.visitTypeId,
    );
    if (visitType.visitTypeName === "Initial Consult") {
      console.log("All notes:", notes);
      console.log(
        "HPI:",
        notes.find((note) => note.noteName === "HISTORY OF PRESENT ILLNESS")
          ?.noteBody,
      );
      console.log(
        "Assessment:",
        notes.find((note) => note.noteName === "ASSESSMENT")?.noteBody,
      );
      console.log(
        "Plan:",
        notes.find((note) => note.noteName === "PLAN")?.noteBody,
      );
      console.log(
        "Chief Complaint:",
        notes.find((note) => note.noteName === "CHIEF COMPLAINT")?.noteBody,
      );
      console.log(
        "Procedure:",
        notes.find((note) => note.noteName === "PROCEDURE")?.noteBody,
      );
      console.log(
        "Instructions:",
        notes.find((note) => note.noteName === "PATIENT INSTRUCTIONS")
          ?.noteBody,
      );
      console.log(
        "Patient ID:",
        currentVisit?.visitName?.match(/\(([^)]+)\)/)?.[1] || "",
      );

      dispatch(
        setNotification({
          name: "Sending to EMR",
          description: "Please do not click this button again.",
          status: "warning",
          duration: 7000,
          isClosable: true,
        }),
      );

      const run = await runClient.start({
        runSkillRequest: {
          skillId: "skill_48aa24cd-7f18-4884-b50c-bd675bd5a3ce",
          inputs: {
            patient_plan:
              notes.find((note) => note.noteName === "PLAN")?.noteBody || "",
            daniel_oauser: "Vdaniel2025",
            daniel_oapass: "Pulmonary2025*",
            patient_ros:
              notes.find((note) => note.noteName === "REVIEW OF SYSTEMS")
                ?.noteBody || "",
            patient_psh:
              notes.find((note) => note.noteName === "Past Social History")
                ?.noteBody || "",
            patient_pmh:
              notes.find((note) => note.noteName === "Past Medical History")
                ?.noteBody || "",
            patient_id: currentVisit.visitName.match(/\(([^)]+)\)/)?.[1] || "",
            patient_assessment:
              notes.find((note) => note.noteName === "ASSESSMENT")?.noteBody ||
              "",
            patient_hpi:
              notes.find(
                (note) => note.noteName === "HISTORY OF PRESENT ILLNESS",
              )?.noteBody || "",
            patient_instructions:
              notes.find((note) => note.noteName === "PATIENT INSTRUCTIONS")
                ?.noteBody || "",
            patient_cc:
              notes.find((note) => note.noteName === "CHIEF COMPLAINT")
                ?.noteBody || "",
            patient_pfh:
              notes.find((note) => note.noteName === "Past Family History")
                ?.noteBody || "",
            patient_pe:
              notes.find((note) => note.noteName === "PHYSICAL EXAM")
                ?.noteBody || "",
          },
        },
      });
      console.log(run);
      dispatch(
        setNotification({
          name: "Note Sent to EMR",
          description:
            "Note sent to EMR. Please do not click this button again.",
          status: "success",
          duration: 7000,
          isClosable: true,
        }),
      );
    }
  };

  useEffect(() => {
    /**
     * Handles click outside event to deselect note.
     * @param {object} event - The click event.
     */
    const handleClickOutside = async (event) => {
      if (noteListRef.current && !noteListRef.current.contains(event.target)) {
        dispatch(setCurrentState({ currentNoteId: "" }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [noteListRef]);

  return (
    <div className={`framenotes-framenotes ${rootClassName}`}>
      <div className="framenotes-notelist" ref={noteListRef}>
        {notes.map((note) =>
          note._id === currentNoteId ? (
            <SelectedNote
              key={note._id}
              id={note._id}
              title={note.noteName}
              body={note.noteBody}
              onClick={() => handleNoteClick("")}
              className=""
            />
          ) : (
            <UnselectedNote
              key={note._id}
              id={note._id}
              title={note.noteName}
              body={note.noteBody}
              onClick={() => handleNoteClick(note._id)}
              noteIsVisible={note.noteIsVisible}
              className=""
            />
          ),
        )}
      </div>
      <div className="framenotes-buttoncontainer">
        {userData.userEmail === "vijaidaniel@yahoo.com" &&
        visitTypes.find((type) => type._id === currentVisit.visitTypeId)
          .visitTypeName === "Initial Consult" ? (
          <Button
            text="Send to EMR"
            rootClassName="button-root-class-name1"
            className=""
            onClick={handleSendToEMRClickVijayDaniel}
            image={"/icons/rocket.svg"}
          />
        ) : (
          <Button
            text="Copy all"
            rootClassName="button-root-class-name1"
            image={buttonIconSrc}
            onClick={handleCopyAllClick}
          />
        )}

        {!currentVisit.visitTypeUptoDate && (
          <DarkButton
            text="Regenerate"
            rootClassName="button-root-class-name1"
            className=""
            onClick={handleRegenerateClick}
            image="/icons/redo2.svg"
          />
        )}
      </div>
    </div>
  );
};

FrameNotes.defaultProps = {
  rootClassName: "",
};

FrameNotes.propTypes = {
  rootClassName: PropTypes.string,
};

export default FrameNotes;
