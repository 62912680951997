import { useState, useEffect, useRef, useCallback } from "react";
import { RealtimeTranscriber } from "assemblyai";
import { apiGetAssemblyApiKey } from "./apiService";

/**
 * Internal Constants
 */
const MAX_16BIT_INT = 32767;
const SAMPLE_RATE = 16000;
const BUFFER_DURATION_MS = 100;
const BUFFER_SIZE_SECONDS = 0.1;

/**
 * AudioProcessor Script
 */
const AudioProcessorScript = `
  class AudioProcessor extends AudioWorkletProcessor {
    process(inputs) {
      try {
        const input = inputs[0];
        if (!input) throw new Error('No input');

        const channelData = input[0];
        if (!channelData) throw new Error('No channel data');

        const float32Array = Float32Array.from(channelData);
        const int16Array = Int16Array.from(
          float32Array.map(n => n * ${MAX_16BIT_INT})
        );
        const buffer = int16Array.buffer;
        this.port.postMessage({ audio_data: buffer });

        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    }
  }
  registerProcessor('audio-processor', AudioProcessor);
`;

/**
 * Create Microphone Instance
 * @returns {object} - Microphone instance with requestPermission, startRecording, and stopRecording methods.
 */
const createMicrophone = () => {
  let stream;
  let audioContext;
  let audioWorkletNode;
  let source;
  let audioBufferQueue = new Int16Array(0);

  return {
    async requestPermission() {
      stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    },
    async startRecording(onAudioCallback) {
      if (!stream)
        stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      audioContext = new AudioContext({
        sampleRate: SAMPLE_RATE,
        latencyHint: "balanced",
      });
      source = audioContext.createMediaStreamSource(stream);

      const blob = new Blob([AudioProcessorScript], {
        type: "application/javascript",
      });
      const url = URL.createObjectURL(blob);
      await audioContext.audioWorklet.addModule(url);
      URL.revokeObjectURL(url);

      audioWorkletNode = new AudioWorkletNode(audioContext, "audio-processor");
      source.connect(audioWorkletNode);
      audioWorkletNode.connect(audioContext.destination);

      audioWorkletNode.port.onmessage = (event) => {
        const currentBuffer = new Int16Array(event.data.audio_data);
        audioBufferQueue = mergeBuffers(audioBufferQueue, currentBuffer);

        const bufferDuration =
          (audioBufferQueue.length / audioContext.sampleRate) * 1000;

        if (bufferDuration >= BUFFER_DURATION_MS) {
          const totalSamples = Math.floor(
            audioContext.sampleRate * BUFFER_SIZE_SECONDS,
          );
          const finalBuffer = new Uint8Array(
            audioBufferQueue.subarray(0, totalSamples).buffer,
          );
          audioBufferQueue = audioBufferQueue.subarray(totalSamples);
          if (onAudioCallback) onAudioCallback(finalBuffer);
        }
      };
    },
    stopRecording() {
      stream?.getTracks().forEach((track) => track.stop());
      audioContext?.close();
      audioBufferQueue = new Int16Array(0);
    },
  };
};

/**
 * Merge Buffers
 * @param {Int16Array} lhs - First buffer
 * @param {Int16Array} rhs - Second buffer
 * @returns {Int16Array} - Merged buffer
 */
const mergeBuffers = (lhs, rhs) => {
  const mergedBuffer = new Int16Array(lhs.length + rhs.length);
  mergedBuffer.set(lhs, 0);
  mergedBuffer.set(rhs, lhs.length);
  return mergedBuffer;
};

/**
 * audioTranscribe Hook
 * @param {string} initialTranscript - Initial transcript to append to new recordings.
 * @returns {object} - Hook state and actions for real-time transcription
 */
export const audioTranscribe = (initialTranscript = "") => {
  const [transcript, setTranscript] = useState(initialTranscript);
  const [errors, setErrors] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const transcriberRef = useRef(null);
  const microphoneRef = useRef(null);

  useEffect(() => {
    const blob = new Blob([AudioProcessorScript], {
      type: "application/javascript",
    });
    const url = URL.createObjectURL(blob);

    return () => {
      URL.revokeObjectURL(url);
    };
  }, []);

  const startRecording = useCallback(async () => {
    try {
      if (isRecording) {
        if (transcriberRef.current) {
          console.log("closing transcriber");
          await transcriberRef.current.close();
          transcriberRef.current = null;
        }

        if (microphoneRef.current) {
          console.log("stopping microphone");
          microphoneRef.current.stopRecording();
          microphoneRef.current = null;
        }

        setIsRecording(false);
      }
    } catch (error) {
      console.log("error", error);
    }

    microphoneRef.current = createMicrophone();
    await microphoneRef.current.requestPermission();

    try {
      const { success, token } = await apiGetAssemblyApiKey();
      if (!success) throw new Error("Failed to retrieve AssemblyAI token");

      const wordBoost = ["pound"];
      transcriberRef.current = new RealtimeTranscriber({ token, wordBoost });

      const texts = {};

      transcriberRef.current.on("transcript", (message) => {
        texts[message.audio_start] = message.text;
        const sortedKeys = Object.keys(texts).sort((a, b) => a - b);
        const combinedText = sortedKeys.map((key) => texts[key]).join(" ");
        setTranscript(`${initialTranscript} ${combinedText}`);
      });

      transcriberRef.current.on("error", async (error) => {
        console.error(error);
        setErrors((prevErrors) => [...prevErrors, error]);
        await transcriberRef.current.close();
      });

      transcriberRef.current.on("close", (event) => {
        console.log(event);
        transcriberRef.current = null;
      });

      await transcriberRef.current.connect();

      await microphoneRef.current.startRecording((audioData) => {
        transcriberRef.current.sendAudio(audioData);
      });

      setIsRecording(true);
    } catch (error) {
      console.error("Error setting up real-time transcription:", error);
      setErrors((prevErrors) => [...prevErrors, error]);
    }
  }, [isRecording, initialTranscript]);

  const stopRecording = useCallback(async () => {
    console.log("isRecording", isRecording);
    if (!isRecording) return;

    if (transcriberRef.current) {
      console.log("closing transcriber");
      await transcriberRef.current.close();
      transcriberRef.current = null;
    }

    if (microphoneRef.current) {
      console.log("stopping microphone");
      microphoneRef.current.stopRecording();
      microphoneRef.current = null;
    }
    console.log("setting isRecording to false");
    setIsRecording(false);
  }, [isRecording]);

  return {
    startRecording,
    stopRecording,
    transcript,
    errors,
    isRecording,
  };
};
