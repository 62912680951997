/**
 * External Dependencies
 */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

/**
 * Internal Components
 */
import Dropdown from "../../shared/dropdown/dropdown";

/**
 * Internal Dependencies
 * - Styles
 * - Redux Actions
 */
import "./visitnamecontainer.css";
import {
  setCurrentState,
  setVisit,
  setVisitType,
  deleteVisitType,
} from "../../../redux/actions";

/**
 * VisitNameContainer Component
 * @returns {JSX.Element} VisitNameContainer component
 */
const VisitNameContainer = () => {
  /**
   * @dispatch - Redux dispatch function to send actions to the store.
   * @currentTab - Current tab from the global state.
   * @currentVisitId - Current visit ID from the global state.
   * @currentVisitTypeId - Current visit type ID from the global state.
   * @currentVisit - Current visit based on the current visit ID.
   * @visitTypes - Visit types from the global state.
   * @currentVisitType - Current visit type based on the current visit type ID.
   * @isEditing - State for editing the visit name.
   * @name - State for the visit name.
   * @setName - Function to update the visit name state.
   * @showDropdown - State for showing the dropdown.
   * @setShowDropdown - Function to update the show dropdown state.
   * @dropdownRef - Reference to the dropdown DOM element.
   * @inputRef - Reference to the input DOM element.
   */
  const currentUser = useSelector((state) => state.globalData.userData);
  const dispatch = useDispatch();
  const currentTab = useSelector(
    (state) => state.globalData.currentState.currentTab,
  );
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const currentVisitTypeId = useSelector(
    (state) => state.globalData.currentState.currentVisitTypeId,
  );
  const currentVisit = useSelector((state) =>
    state.globalData.userData.visits.find(
      (visit) => visit._id === currentVisitId,
    ),
  );
  const visitTypes = useSelector(
    (state) => state.globalData.userData.visitTypes,
  );
  const currentVisitType = visitTypes.find(
    (visitType) => visitType._id === currentVisitTypeId,
  );

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  // Update the name state based on the current tab and visit information
  useEffect(() => {
    if (currentTab === "TEMPLATES") {
      setName(currentVisitType?.visitTypeName || "");
    } else {
      setName(currentVisit?.visitName || "");
    }
  }, [currentTab, currentVisitType, currentVisit]);

  // Handle outside click to close dropdown
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // Select the entire visit name when editing
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.select();
    }
    // if (!isEditing) {
    //   if (name === '' && currentTab === 'TEMPLATES') {
    //     dispatch(setVisitType(currentVisitTypeId, { visitTypeName: 'Untitled Template' }));
    //   } else if (name === '' && currentTab !== 'TEMPLATES') {
    //     dispatch(setVisit(currentVisitId, { visitName: 'New Encounter' }));
    //   }
    // }
  }, [isEditing]);

  /**
   * Handles the click event to enable editing of the visit name.
   */
  const handleNameClick = () => {
    setShowDropdown(false);
    setIsEditing(true);
  };

  /**
   * Handles the change event for the visit name input.
   * @param {object} event - The change event.
   */
  const handleNameChange = (event) => {
    setName(event.target.value);
    if (currentTab === "TEMPLATES") {
      dispatch(
        setVisitType(currentVisitTypeId, { visitTypeName: event.target.value }),
      );
    } else {
      dispatch(setVisit(currentVisitId, { visitName: event.target.value }));
    }
  };

  /**
   * Handles the key press event for the visit name input.
   * @param {object} event - The key press event.
   */
  const handleNameKeyPress = (event) => {
    if (event.key === "Enter") {
      setIsEditing(false);
      if (currentTab === "TEMPLATES") {
        dispatch(setVisitType(currentVisitTypeId, { visitTypeName: name }));
      } else {
        dispatch(setVisit(currentVisitId, { visitName: name }));
      }
    }
  };

  /**
   * Toggles the visibility of the dropdown.
   */
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  /**
   * Handles the selection of a visit type from the dropdown.
   * @param {string} newVisitTypeId - The ID of the selected visit type.
   */
  const handleVisitTypeClick = (newVisitTypeId) => {
    dispatch(setVisit(currentVisitId, { visitTypeId: newVisitTypeId }));
    setShowDropdown(false);
  };

  /**
   * Handles the creation of a new visit type.
   */
  const handleNewVisitTypeClick = () => {
    dispatch(
      setCurrentState({
        currentVisitId: "",
        currentNoteId: "",
        currentTab: "TCENTER",
      }),
    );
    setShowDropdown(false);
  };

  /**
   * Handles the creation of a new visit type.
   */
  const handleTranscriptClick = () => {
    dispatch(setCurrentState({ currentTab: "TRANSCRIPT" }));
    setShowDropdown(false);
  };

  /**
   * Handles the creation of a new visit type.
   */
  const handleNotesClick = () => {
    dispatch(setCurrentState({ currentTab: "NOTES" }));
    setShowDropdown(false);
  };

  /**
   * Handles downloading the note as a PDF file
   */
  const handleDownloadClick = () => {
    // Check if currentVisit exists
    if (
      !currentVisit ||
      !currentVisit.notes ||
      currentVisit.notes.length === 0
    ) {
      console.log("No data available to download.");
      return;
    }

    // Check if running in a WebViewer
    const isWebView =
      /(WebView|wv)/.test(navigator.userAgent) ||
      /Android.*Version\/[0-9]/.test(navigator.userAgent) ||
      typeof window.ReactNativeWebView !== "undefined";

    if (isWebView) {
      // For WebView, create a message to send to the native app
      const noteData = {
        visitName: currentVisit.visitName,
        visitCreatedDate: currentVisit.visitCreatedDate,
        notes: currentVisit.notes,
        visitTypeId: currentVisit.visitTypeId,
        userEmail: currentUser.userEmail,
        action: "download",
        needsDisclaimer: currentUser.userEmail === "drzawoffice@icloud.com",
        customHeader:
          currentUser.userEmail === "drzawoffice@icloud.com"
            ? {
                type: "text",
                centerHeader: {
                  name: "Myint Zaw, MD",
                  specialty: "Internal Medicine",
                },
                rightHeader: {
                  address1: "6335 N. Fresno St. #101",
                  address2: "Fresno, CA 93710",
                  phone: "Phone (559) 436-1444",
                  fax: "Fax (559) 436-4395",
                },
              }
            : null,
      };

      // Send data to native app
      try {
        if (window.ReactNativeWebView) {
          // React Native WebView
          window.ReactNativeWebView.postMessage(JSON.stringify(noteData));
        } else if (
          window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.download
        ) {
          // iOS WKWebView
          window.webkit.messageHandlers.download.postMessage(noteData);
        } else if (
          window.Android &&
          typeof window.Android.downloadPDF === "function"
        ) {
          // Android WebView
          window.Android.downloadPDF(JSON.stringify(noteData));
        } else {
          alert("Download functionality is not available in this environment.");
        }
      } catch (error) {
        console.error("Error sending download request to native app:", error);
        alert("Unable to download. Please try again later.");
      }
    } else {
      // Original browser implementation
      import("jspdf")
        .then(({ default: jsPDF }) => {
          import("html2canvas").then(({ default: html2canvas }) => {
            const { visitName, visitCreatedDate, notes, visitTypeId } =
              currentVisit;

            // Function to convert text with ** markers to HTML with bold tags
            const convertAsterisksToBold = (text) => {
              // Replace **text** with <strong>text</strong>
              return text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
            };

            // Create a temporary container for the content
            const container = document.createElement("div");
            container.style.position = "absolute";
            container.style.left = "-9999px";
            container.style.width = "800px"; // Set a fixed width for better PDF rendering
            container.style.fontFamily = "Arial, sans-serif";
            // Add padding to the container
            container.style.padding = "30px";
            // Add a border to visualize the content area (will not appear in PDF)
            container.style.boxSizing = "border-box";
            document.body.appendChild(container);

            // Determine which logo to use
            let imageContent = "";
            let imageUrl = "";
            if (
              currentUser.userEmail === "rkrueger@midvalleycardiology.com" ||
              currentUser.userEmail === "marcine@midvalley.com" ||
              currentUser.userEmail === "krueger@midvalley.com" ||
              currentUser.userEmail === "asako@midvalley.com"
            ) {
              imageUrl = "/images/midvalley-logo.jpeg";
              imageContent = `<img src="${imageUrl}" alt="Midvalley Logo" style="max-width: 200px; height: auto;">`;
            } else if (currentUser.userEmail === "tamahal@yahoo.com") {
              imageUrl = "/images/tmahal-logo.png";
              imageContent = `<img src="${imageUrl}" alt="Tej Mahal Private Practice Logo" style="max-width: 200px; height: auto;">`;
            } else if (
              currentUser.userEmail === "drmydili@firstpeds.com" ||
              currentUser.userEmail === "student@firstpeds.com" ||
              currentUser.userEmail === "tarisha.saini@gmail.com"
            ) {
              imageUrl = "/images/firstpeds-logo.png";
              imageContent = `<img src="${imageUrl}" alt="First Pediatrics Logo" style="max-width: 200px; height: auto;">`;
            } else if (currentUser.userEmail === "drzawoffice@icloud.com") {
              // Replace image with styled text divs
              imageContent = `
                <div style="position: relative; width: 100%; height: auto; margin-bottom: 20px; padding-top: 10px;">
                  <div style="text-align: center; font-family: Arial, sans-serif;">
                    <div style="font-size: 18px; font-weight: bold;">Myint Zaw, MD</div>
                    <div style="font-size: 16px;">Internal Medicine</div>
                  </div>
                  <div style="position: absolute; top: 0; right: 0; text-align: right; font-family: Arial, sans-serif; font-size: 12px; line-height: 1.4;">
                    <div>6335 N. Fresno St. #101</div>
                    <div>Fresno, CA 93710</div>
                    <div>Phone (559) 436-1444</div>
                    <div>Fax (559) 436-4395</div>
                  </div>
                </div>
              `;
              // Remove imageUrl since we're not using images anymore
              imageUrl = "";
            }

            const currentVisitType = visitTypes.find(
              (visitType) => visitType._id === visitTypeId,
            );

            const isReferralLetterForTamahal =
              currentUser.userEmail === "tamahal@yahoo.com" &&
              currentVisitType &&
              currentVisitType.visitTypeName === "Referral Letter";

            // Adjust content padding based on document type
            const contentPadding = isReferralLetterForTamahal
              ? "0 96px"
              : "0 15px";

            // Create the logo section
            const logoDiv = document.createElement("div");
            logoDiv.style.textAlign = "center";
            logoDiv.style.marginBottom = "30px"; // Increased margin
            logoDiv.innerHTML = imageContent;
            container.appendChild(logoDiv);

            // Only show the name and header section if it's not a Referral Letter for tamahal
            if (!isReferralLetterForTamahal) {
              const headerDiv = document.createElement("div");
              headerDiv.style.padding = contentPadding;
              headerDiv.style.marginBottom = "20px"; // Add margin below header

              const nameP = document.createElement("p");
              nameP.style.fontSize = "14px";
              nameP.style.fontWeight = "bold";
              nameP.style.margin = "0 0 10px 0"; // Add margin below name
              nameP.innerHTML = `Name: ${convertAsterisksToBold(visitName)}`;
              headerDiv.appendChild(nameP);

              if (
                currentUser.userEmail === "drmydili@firstpeds.com" ||
                currentUser.userEmail === "student@firstpeds.com" ||
                currentUser.userEmail === "tarisha.saini@gmail.com"
              ) {
                const dateP = document.createElement("p");
                dateP.style.fontSize = "14px";
                dateP.style.fontWeight = "bold";
                dateP.style.margin = "0 0 10px 0"; // Add margin below date
                dateP.innerHTML = `Date of Service: ${visitCreatedDate.split(" • ")[0]}`;
                headerDiv.appendChild(dateP);
              }

              container.appendChild(headerDiv);
            }

            // Add each note with improved spacing
            notes.forEach((note, index) => {
              // Apply bold formatting to note body
              let noteBodyWithLineBreaks = note.noteBody.replace(/\n/g, "<br>");
              noteBodyWithLineBreaks = convertAsterisksToBold(
                noteBodyWithLineBreaks,
              );

              const noteDiv = document.createElement("div");
              noteDiv.style.padding = contentPadding;
              noteDiv.style.marginBottom = "25px"; // Add margin between notes

              // For Referral Letter for tamahal, don't show the note name
              if (isReferralLetterForTamahal) {
                const bodyP = document.createElement("p");
                bodyP.style.fontSize = "12px";
                bodyP.style.fontWeight = "normal";
                bodyP.style.margin = "0 0 15px 0"; // Increased margin
                bodyP.style.lineHeight = "1.4"; // Improved line height for readability
                bodyP.innerHTML = noteBodyWithLineBreaks;
                noteDiv.appendChild(bodyP);
              } else {
                const nameP = document.createElement("p");
                nameP.style.fontSize = "14px";
                nameP.style.fontWeight = "bold";
                nameP.style.margin = "0 0 10px 0"; // Add margin below note name
                nameP.style.lineHeight = "1.5"; // Improved line height
                nameP.innerHTML = convertAsterisksToBold(note.noteName);
                noteDiv.appendChild(nameP);

                const bodyP = document.createElement("p");
                bodyP.style.fontSize = "12px";
                bodyP.style.fontWeight = "normal";
                bodyP.style.margin = "0 0 15px 0"; // Increased margin
                bodyP.style.lineHeight = "1.4"; // Improved line height for readability
                bodyP.innerHTML = noteBodyWithLineBreaks;
                noteDiv.appendChild(bodyP);
              }

              // Add a separator line between notes (except for the last note)
              if (index < notes.length - 1 && !isReferralLetterForTamahal) {
                const separator = document.createElement("hr");
                separator.style.border = "none";
                separator.style.borderTop = "1px solid #ddd";
                separator.style.margin = "20px 0";
                noteDiv.appendChild(separator);
              }

              container.appendChild(noteDiv);
            });

            // Add disclaimer for Dr. Zaw account
            if (currentUser.userEmail === "drzawoffice@icloud.com") {
              const disclaimerDiv = document.createElement("div");
              disclaimerDiv.style.marginTop = "30px";
              disclaimerDiv.style.padding = "15px";
              disclaimerDiv.style.borderTop = "1px solid #ddd";

              const disclaimerP = document.createElement("p");
              disclaimerP.style.fontSize = "10px";
              disclaimerP.style.fontStyle = "italic";
              disclaimerP.innerHTML =
                "Disclaimer: This note was created using an AI scribe. It is possible that typographical errors may have occurred. Any questions about content or clarifications should be directed to the medical provider.";

              disclaimerDiv.appendChild(disclaimerP);
              container.appendChild(disclaimerDiv);
            }

            // Create a sanitized filename from the visit name
            const sanitizedName = visitName
              .replace(/[^a-z0-9]/gi, "_")
              .toLowerCase();
            const fileName = `${sanitizedName}_note.pdf`;

            // Use html2canvas to capture the container as an image
            html2canvas(container, {
              scale: 2, // Higher scale for better quality
              useCORS: true, // Allow images from other domains
              logging: false, // Disable logging
              backgroundColor: "#ffffff", // Ensure white background
            }).then((canvas) => {
              // Create PDF with proper dimensions
              const imgWidth = 210; // A4 width in mm
              const pageHeight = 297; // A4 height in mm
              const imgHeight = (canvas.height * imgWidth) / canvas.width;
              const pdf = new jsPDF("p", "mm", "a4");

              // Add margins by adjusting the image position and size
              const margin = 10; // 10mm margins
              const printWidth = imgWidth - margin * 2;
              const printHeight = (printWidth * canvas.height) / canvas.width;

              let heightLeft = printHeight;
              let position = margin; // Start at top margin

              // Add image to first page with margins
              pdf.addImage(
                canvas.toDataURL("image/png"),
                "PNG",
                margin, // Left margin
                position, // Top position
                printWidth, // Width with margins
                printHeight, // Height adjusted to maintain aspect ratio
              );

              heightLeft -= pageHeight - margin * 2;

              // Add new pages if content overflows
              while (heightLeft >= 0) {
                position = heightLeft - printHeight + margin;
                pdf.addPage();
                pdf.addImage(
                  canvas.toDataURL("image/png"),
                  "PNG",
                  margin, // Left margin
                  position, // Position adjusted for next page
                  printWidth, // Width with margins
                  printHeight, // Height adjusted to maintain aspect ratio
                );
                heightLeft -= pageHeight - margin * 2;
              }

              // Save the PDF
              pdf.save(fileName);

              // Clean up
              document.body.removeChild(container);
            });
          });
        })
        .catch((error) => {
          console.error("Error loading PDF libraries:", error);
          alert("Unable to generate PDF. Please try again later.");
        });
    }
  };

  /**
   * Handles the print click
   */
  const handlePrintClick = () => {
    // Check if currentVisit exists
    if (
      !currentVisit ||
      !currentVisit.notes ||
      currentVisit.notes.length === 0
    ) {
      console.log("No data available to print.");
      return;
    }

    // Check if running in a WebViewer
    const isWebView =
      /(WebView|wv)/.test(navigator.userAgent) ||
      /Android.*Version\/[0-9]/.test(navigator.userAgent) ||
      typeof window.ReactNativeWebView !== "undefined";

    if (isWebView) {
      // For WebView, create a message to send to the native app
      const noteData = {
        visitName: currentVisit.visitName,
        visitCreatedDate: currentVisit.visitCreatedDate,
        notes: currentVisit.notes,
        visitTypeId: currentVisit.visitTypeId,
        userEmail: currentUser.userEmail,
        action: "print",
        needsDisclaimer: currentUser.userEmail === "drzawoffice@icloud.com",
        customHeader:
          currentUser.userEmail === "drzawoffice@icloud.com"
            ? {
                type: "text",
                centerHeader: {
                  name: "Myint Zaw, MD",
                  specialty: "Internal Medicine",
                },
                rightHeader: {
                  address1: "6335 N. Fresno St. #101",
                  address2: "Fresno, CA 93710",
                  phone: "Phone (559) 436-1444",
                  fax: "Fax (559) 436-4395",
                },
              }
            : null,
      };

      // Send data to native app
      try {
        if (window.ReactNativeWebView) {
          // React Native WebView
          window.ReactNativeWebView.postMessage(JSON.stringify(noteData));
        } else if (
          window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.print
        ) {
          // iOS WKWebView
          window.webkit.messageHandlers.print.postMessage(noteData);
        } else if (
          window.Android &&
          typeof window.Android.printPDF === "function"
        ) {
          // Android WebView
          window.Android.printPDF(JSON.stringify(noteData));
        } else {
          alert("Print functionality is not available in this environment.");
        }
      } catch (error) {
        console.error("Error sending print request to native app:", error);
        alert("Unable to print. Please try again later.");
      }
    } else {
      // Original browser implementation
      const { visitName, visitCreatedDate, notes, visitTypeId } = currentVisit;

      // Function to convert text with ** markers to HTML with bold tags
      const convertAsterisksToBold = (text) => {
        // Replace **text** with <strong>text</strong>
        return text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
      };

      // Determine which logo to use
      let imageContent = "";
      let imageUrl = "";
      if (
        currentUser.userEmail === "rkrueger@midvalleycardiology.com" ||
        currentUser.userEmail === "marcine@midvalley.com" ||
        currentUser.userEmail === "krueger@midvalley.com" ||
        currentUser.userEmail === "asako@midvalley.com"
      ) {
        imageUrl = "/images/midvalley-logo.jpeg";
        imageContent = `<img src="${imageUrl}" alt="Midvalley Logo" style="max-width: 200px; height: auto;">`;
      } else if (currentUser.userEmail === "tamahal@yahoo.com") {
        imageUrl = "/images/tmahal-logo.png";
        imageContent = `<img src="${imageUrl}" alt="Tej Mahal Private Practice Logo" style="max-width: 200px; height: auto;">`;
      } else if (
        currentUser.userEmail === "drmydili@firstpeds.com" ||
        currentUser.userEmail === "student@firstpeds.com" ||
        currentUser.userEmail === "tarisha.saini@gmail.com"
      ) {
        imageUrl = "/images/firstpeds-logo.png";
        imageContent = `<img src="${imageUrl}" alt="First Pediatrics Logo" style="max-width: 200px; height: auto;">`;
      } else if (currentUser.userEmail === "drzawoffice@icloud.com") {
        // Replace image with styled text divs
        imageContent = `
          <div style="position: relative; width: 100%; height: auto; margin-bottom: 20px; padding-top: 10px;">
            <div style="text-align: center; font-family: Arial, sans-serif;">
              <div style="font-size: 18px; font-weight: bold;">Myint Zaw, MD</div>
              <div style="font-size: 16px;">Internal Medicine</div>
            </div>
            <div style="position: absolute; top: 0; right: 0; text-align: right; font-family: Arial, sans-serif; font-size: 12px; line-height: 1.4;">
              <div>6335 N. Fresno St. #101</div>
              <div>Fresno, CA 93710</div>
              <div>Phone (559) 436-1444</div>
              <div>Fax (559) 436-4395</div>
            </div>
          </div>
        `;
        // Remove imageUrl since we're not using images anymore
        imageUrl = "";
      }

      const currentVisitType = visitTypes.find(
        (visitType) => visitType._id === visitTypeId,
      );

      const isReferralLetterForTamahal =
        currentUser.userEmail === "tamahal@yahoo.com" &&
        currentVisitType &&
        currentVisitType.visitTypeName === "Referral Letter";

      const contentPadding = isReferralLetterForTamahal ? "0 96px" : "0";

      let printableContent = "";

      // Special header for Dr. Zaw's account with text instead of images
      if (currentUser.userEmail === "drzawoffice@icloud.com") {
        printableContent = `
          <div style="position: relative; width: 100%; height: auto; margin-bottom: 30px; padding-top: 10px;">
            <div style="text-align: center; font-family: Arial, sans-serif;">
              <div style="font-size: 18px; font-weight: bold;">Myint Zaw, MD</div>
              <div style="font-size: 16px;">Internal Medicine</div>
            </div>
            <div style="position: absolute; top: 0; right: 0; text-align: right; font-family: Arial, sans-serif; font-size: 12px; line-height: 1.4;">
              <div>6246 N. Fresno St. #101</div>
              <div>Fresno, CA 93710</div>
              <div>Phone (559) 436-1444</div>
              <div>Fax (559) 436-4395</div>
            </div>
          </div>
        `;
      } else {
        // Original image content for other users
        printableContent = `
          <div style="text-align: center; margin-bottom: 20px;">
            ${imageContent}
          </div>
        `;
      }

      // Only show the name and header section if it's not a Referral Letter for tamahal
      if (!isReferralLetterForTamahal) {
        printableContent += `
          <div style="padding: ${contentPadding};">
            <br>
            <p style="font-size: 14px; font-weight: bold; margin: 0;">Name: ${convertAsterisksToBold(visitName)}</p>
            ${
              currentUser.userEmail === "drmydili@firstpeds.com" ||
              currentUser.userEmail === "student@firstpeds.com" ||
              currentUser.userEmail === "tarisha.saini@gmail.com"
                ? `<p style="font-size: 14px; font-weight: bold; margin: 0;">Date of Service: ${visitCreatedDate.split(" • ")[0]}</p>`
                : ""
            }
            <br>
          </div>
        `;
      }

      notes.forEach((note) => {
        // Apply bold formatting to note body
        let noteBodyWithLineBreaks = note.noteBody.replace(/\n/g, "<br>");
        noteBodyWithLineBreaks = convertAsterisksToBold(noteBodyWithLineBreaks);

        // For Referral Letter for tamahal, don't show the note name
        if (isReferralLetterForTamahal) {
          printableContent += `
            <div style="padding: ${contentPadding};">
              <p style="font-size: 12px; font-weight: normal; margin: 0 0 12px 0; line-height: 1;">${noteBodyWithLineBreaks}</p>
            </div>
          `;
        } else {
          printableContent += `
            <div style="padding: ${contentPadding};">
              <p style="font-size: 14px; font-weight: bold; margin: 0; line-height: 2;">${convertAsterisksToBold(note.noteName)}</p>
              <p style="font-size: 12px; font-weight: normal; margin: 0 0 12px 0; line-height: 1;">${noteBodyWithLineBreaks}</p>
            </div>
          `;
        }
      });

      // Add disclaimer for Dr. Zaw account
      const needsDisclaimer =
        currentUser.userEmail === "drzawoffice@icloud.com";

      let disclaimerContent = "";
      if (needsDisclaimer) {
        disclaimerContent = `
          <div style="margin-top: 30px; padding: 15px; border-top: 1px solid #ddd;">
            <p style="font-size: 10px; font-style: italic;">
              Disclaimer: This note was created using an AI scribe. It is possible that typographical errors may have occurred. Any questions about content or clarifications should be directed to the medical provider.
            </p>
          </div>
        `;
      }

      const fullHtml = `
        <html>
          <head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <style>
              body {
                font-family: Arial, sans-serif;
                padding: 15px;
              }
              @media print {
                body {
                  padding: 0;
                }
              }
            </style>
          </head>
          <body>
            ${printableContent}
            ${disclaimerContent}
          </body>
        </html>
      `;

      // Check if we're on a mobile device
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        );

      if (isMobile) {
        // For mobile devices, create a new window with the content
        const printWindow = window.open("", "_blank");
        if (printWindow) {
          printWindow.document.write(fullHtml);
          printWindow.document.close();

          // Add a print button for mobile users
          const printButton = printWindow.document.createElement("button");
          printButton.innerHTML = "Print";
          printButton.style.position = "fixed";
          printButton.style.bottom = "20px";
          printButton.style.right = "20px";
          printButton.style.padding = "10px 20px";
          printButton.style.backgroundColor = "#4CAF50";
          printButton.style.color = "white";
          printButton.style.border = "none";
          printButton.style.borderRadius = "5px";
          printButton.style.zIndex = "9999";

          printButton.onclick = function () {
            printButton.style.display = "none";
            setTimeout(() => {
              printWindow.print();
              setTimeout(() => {
                printButton.style.display = "block";
              }, 500);
            }, 300);
          };

          printWindow.document.body.appendChild(printButton);
        } else {
          console.error("Could not open print window");
          alert(
            "Unable to open print window. Please check your browser settings.",
          );
        }
      } else {
        // For desktop browsers, use the iframe approach
        const printFrame = document.createElement("iframe");
        printFrame.style.position = "absolute";
        printFrame.style.top = "-9999px";
        document.body.appendChild(printFrame);

        const printDocument =
          printFrame.contentDocument || printFrame.contentWindow.document;
        printDocument.write(fullHtml);
        printDocument.close();

        // Wait for any images to load before printing
        if (imageUrl) {
          const img = new Image();
          img.onload = function () {
            setTimeout(() => {
              printFrame.contentWindow.focus();
              printFrame.contentWindow.print();
              // Remove the iframe after printing
              setTimeout(() => {
                document.body.removeChild(printFrame);
              }, 1000);
            }, 500);
          };
          img.src = imageUrl;
        } else {
          setTimeout(() => {
            printFrame.contentWindow.focus();
            printFrame.contentWindow.print();
            // Remove the iframe after printing
            setTimeout(() => {
              document.body.removeChild(printFrame);
            }, 1000);
          }, 500);
        }
      }
    }
  };

  /**
   * Handles the deletion of a template.
   * @param {string} visitTypeId - The ID of the visit type to be deleted.
   */
  const handleDeleteClick = (visitTypeId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this template?",
    );
    if (isConfirmed) {
      dispatch(deleteVisitType(visitTypeId));
      dispatch(
        setCurrentState({
          currentVisitId: "",
          currentNoteId: "",
          currentVisitTypeId: "",
          currentTab: "TCENTER",
        }),
      );
    }
  };

  const buttonList =
    currentTab === "TEMPLATES"
      ? [
          {
            image: "/icons/pencil1.svg",
            text: "Edit name",
            onClick: handleNameClick,
          },
          {
            image: "/icons/trash.svg",
            text: "Delete template",
            onClick: () => {
              handleDeleteClick(currentVisitTypeId);
            },
          },
        ]
      : window.innerWidth <= 740
        ? [
            {
              image: "/icons/pencil1.svg",
              text: "Edit name",
              onClick: handleNameClick,
            },
            {
              image: "/icons/transcript.svg",
              text: "Transcript",
              onClick: handleTranscriptClick,
            },
            {
              image: "/icons/notes.svg",
              text: "Notes",
              onClick: handleNotesClick,
            },
            {
              image: "/icons/rocket.svg",
              text: "Save as PDF",
              onClick: handleDownloadClick,
            },
            {
              image: "/icons/outbound.svg",
              text: "Print note",
              onClick: handlePrintClick,
            },
          ]
        : [
            {
              image: "/icons/pencil1.svg",
              text: "Edit name",
              onClick: handleNameClick,
            },
            {
              image: "/icons/download.svg",
              text: "Download note",
              onClick: handleDownloadClick,
            },
            {
              image: "/icons/outbound.svg",
              text: "Print note",
              onClick: handlePrintClick,
            },
          ];

  const sectionList =
    currentTab !== "RECORD" && currentTab !== "TEMPLATES"
      ? [
          {
            name: "TEMPLATES",
            labelImage: "/icons/plus.svg",
            labelAction: handleNewVisitTypeClick,
            options: visitTypes
              .filter(
                (visitType) =>
                  visitType.visitTypeState !== "CREATING" &&
                  visitType.visitTypeState !== "ERROR",
              )
              .sort((a, b) => {
                if (
                  a.visitTypeState === "DEFAULT" &&
                  b.visitTypeState !== "DEFAULT"
                )
                  return 1;
                if (
                  a.visitTypeState !== "DEFAULT" &&
                  b.visitTypeState === "DEFAULT"
                )
                  return -1;
                return 0;
              })
              .map((visitType) => ({
                text:
                  visitType.visitTypeState === "DEFAULT"
                    ? `${visitType.visitTypeName} (HALO)`
                    : visitType.visitTypeName,
                image: "/icons/checkmark.svg",
                onClick: () => handleVisitTypeClick(visitType._id),
                selected: visitType._id === currentVisit?.visitTypeId,
              })),
          },
        ]
      : [];

  return (
    <div className="visitnamecontainer-visitnamecontainer">
      {!(window.innerWidth <= 740 && currentTab === "RECORD") && (
        <>
          {isEditing ? (
            <input
              type="text"
              value={name}
              onChange={handleNameChange}
              onKeyPress={handleNameKeyPress}
              onBlur={() => setIsEditing(false)}
              autoFocus
              ref={inputRef}
              className="input3"
            />
          ) : (
            <div
              className="visitnamecontainer-visitname"
              onClick={
                currentTab === "RECORD" || currentTab === "TEMPLATES"
                  ? handleNameClick
                  : toggleDropdown
              }
            >
              <span className="visitnamecontainer-name tl4">{name}</span>
              <img
                src={
                  currentTab === "RECORD" || currentTab === "TEMPLATES"
                    ? "/icons/pencil1.svg"
                    : "/icons/down.svg"
                }
                className="visitnamecontainer-image img4"
              />
            </div>
          )}
          {showDropdown && (
            <div ref={dropdownRef}>
              <Dropdown
                buttonList={buttonList}
                sectionList={sectionList}
                showBorder={true}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

VisitNameContainer.defaultProps = {
  name: "New Encounter",
};

VisitNameContainer.propTypes = {
  name: PropTypes.string,
};

export default VisitNameContainer;
