/**
 * External Dependencies
 */
import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";

/**
 * Internal Components
 */
import Sidebar from "../components/dashboard/sidebar/sidebar";
import TabBar from "../components/dashboard/tabbar/tabbar";
import FrameNotes from "../components/dashboard/notes/framenotes";
import FrameTranscript from "../components/dashboard/transcript/frametranscript";
import FrameRecord from "../components/dashboard/record/framerecord";
import FrameTemplates from "../components/dashboard/templates/frametemplates";
import FrameTCenter from "../components/dashboard/tcenter/frametcenter";
import FrameDiagnoses from "../components/dashboard/diagnoses/framediagnoses";
import Footer from "../components/shared/footer";

/**
 * Internal Dependencies
 * - Styles
 * - Redux Actions
 * - Services and Utilities
 */
import "./dashboard.css";
import {
  setCurrentState,
  setUserData,
  addVisit,
  setNotification,
  upsertUserStats,
} from "../redux/actions";
import {
  generateId,
  apiGetUserData,
  apiSendUserDataViaWebSocket,
  apiSetupWebSocket,
  apiSendUserDataFinal,
  getCurrentDate,
} from "../scripts/apiService";

/**
 * Dashboard Component
 * @returns {JSX.Element} Dashboard component
 */
const Dashboard = () => {
  /**
   * @dispatch - Dispatch function for Redux actions
   * @globalData - Global state data from Redux
   * @currentSessionId - Current session ID from session storage
   * @currentVisitId - Current visit ID from global state
   * @currentTab - Current tab from global state
   * @currentDisplaySidebar - Current display sidebar status from global state
   * @isLoading - State to track loading status
   */
  const dispatch = useDispatch();
  const globalData = useSelector((state) => state.globalData);
  const currentSessionId = sessionStorage.getItem("currentSessionId");
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const currentTab = useSelector(
    (state) => state.globalData.currentState.currentTab,
  );
  const currentDisplaySidebar = useSelector(
    (state) => state.globalData.currentState.currentDisplaySidebar,
  );

  const [isLoading, setIsLoading] = useState(true);
  const [wasDisconnected, setWasDisconnected] = useState(false);
  const socketRef = useRef(null);

  /**
   * Handles WebSocket messages
   * @param {object} response - The WebSocket response
   */
  const handleWebSocketMessage = (response) => {
    if (!response.success) {
      dispatch(
        setNotification({
          name: "Sessions Exceeded",
          description:
            "Your HALO account works on only one device at a time. Please log in again to renew your session.",
          status: "error",
          duration: 5000,
          isClosable: true,
        }),
      );
      alert(
        "Your HALO account works on only one device at a time. Please log in again to renew your session.",
      );
      window.location.href = "/login";
    }
  };

  /**
   * Deletes visits older than 30 days based on the visitCreatedDate string format
   * @param {Array} visits - The array of user visits
   * @returns {Array} The filtered visits array
   */
  const deleteOldVisits = (visits) => {
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    return visits.filter((visit) => {
      const visitDate = new Date(visit.visitCreatedDate.split(" • ")[0]);
      return visitDate >= thirtyDaysAgo;
    });
  };

  /**
   * Handles WebSocket errors
   * @param {object} error - The WebSocket error
   */
  const handleWebSocketError = (error) => {
    console.error("WebSocket error:", error);
  };

  /**
   * Sets up WebSocket connection on mount
   */
  useEffect(() => {
    if (window.innerWidth <= 740) {
      dispatch(setCurrentState({ currentDisplaySidebar: false }));
    }
    socketRef.current = apiSetupWebSocket(
      handleWebSocketMessage,
      handleWebSocketError,
    );

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, []);

  /**
   * Ref to hold debounced function to save user data
   */
  const debouncedSaveUserData = useRef(
    debounce((userData) => {
      apiSendUserDataViaWebSocket(
        socketRef.current,
        currentSessionId,
        userData,
      );
    }, 250),
  ).current;

  /**
   * Ref to hold the latest user data
   */
  const latestUserDataRef = useRef(globalData.userData);
  const latestUserRecordingState = useRef(globalData.userData.userIsRecording);
  const latestLoadingState = useRef(isLoading);

  /**
   * Update latest user data ref when global user data changes
   */
  useEffect(() => {
    latestUserDataRef.current = globalData.userData;
  }, [globalData.userData]);

  /**
   * Update latest user data ref when global user data changes
   */
  useEffect(() => {
    latestUserRecordingState.current = globalData.userData.userIsRecording;
  }, [globalData.userData.userIsRecording]);

  /**
   * Update latest user data ref when global user data changes
   */
  useEffect(() => {
    latestLoadingState.current = isLoading;
  }, [isLoading]);

  /**
   * Monitors network connection status and sends notifications
   */
  useEffect(() => {
    const handleConnectionChange = () => {
      if (navigator.onLine) {
        if (wasDisconnected) {
          dispatch(
            setNotification({
              name: "Internet Reconnected",
              description: "Your internet connection has been restored.",
              status: "success",
              duration: null,
              isClosable: true,
            }),
          );
          setWasDisconnected(false);
        }
      } else {
        if (!wasDisconnected) {
          dispatch(
            setNotification({
              name: "Internet Disconnected",
              description:
                "You are offline. Some features may not work properly.",
              status: "warning",
              duration: null,
              isClosable: true,
            }),
          );
          setWasDisconnected(true);
        }
      }
    };

    // Add event listeners for online and offline events
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    // Initial check for connection status
    handleConnectionChange();

    return () => {
      // Clean up event listeners on unmount
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, [dispatch, wasDisconnected]);

  /**
   * Load user data and initial visit data on mount
   * Set up event listeners for beforeunload, unload, and pagehide
   */
  useEffect(() => {
    dispatch(setCurrentState({ currentSessionId }));

    const loadData = async () => {
      const response = await apiGetUserData(currentSessionId);
      if (response.success) {
        await handleUserDataSuccess(response.data);
        await loadInitialVisit(response.data);
        setIsLoading(false);
      } else {
        dispatch(
          setNotification({
            name: "Session Expired",
            description: "Please log in again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          }),
        );
        alert("Session expired. Please log in again.");
        window.location.href = "/login";
      }
    };
    loadData();

    const updateUserData = () => {
      const updatedUserData = {
        ...latestUserDataRef.current,
        userIsRecording: false,
      };
      updatedUserData.visitTypes = updatedUserData.visitTypes.map(
        (visitType) =>
          visitType.visitTypeState === "CREATING"
            ? { ...visitType, visitTypeState: "ERROR" }
            : visitType,
      );

      updatedUserData.visits = updatedUserData.visits.map((visit) =>
        visit.visitDiagnosesState === "GENERATING"
          ? { ...visit, visitDiagnosesState: "INCOMPLETE" }
          : visit,
      );

      if (!latestLoadingState.current) {
        debouncedSaveUserData(updatedUserData);
      }

      apiSendUserDataFinal(currentSessionId, updatedUserData);
      function delay(ms) {
        var start = +new Date();
        while (+new Date() - start < ms);
      }
      delay(500);
    };

    window.addEventListener("beforeunload", updateUserData);
    window.addEventListener("unload", updateUserData);

    return () => {
      window.removeEventListener("beforeunload", updateUserData);
      window.removeEventListener("unload", updateUserData);
    };
  }, [currentSessionId]);

  /**
   * Save user data when global user data changes and isLoading is false
   */
  useEffect(() => {
    if (!isLoading) {
      debouncedSaveUserData(globalData.userData);
    }
  }, [globalData.userData, isLoading, debouncedSaveUserData]);

  /**
   * Handles the successful retrieval of user data
   * @param {object} userData - The user data retrieved from the API
   */
  const handleUserDataSuccess = async (userData) => {
    userData.visits = deleteOldVisits(userData.visits);
    dispatch(setUserData(userData));
  };

  /**
   * Formats a date object into a readable string
   * @param {Date} date - The date to format
   * @returns {string} The formatted date string
   */
  const formatDateTime = (date) => {
    const dateOptions = { month: "long", day: "numeric", year: "numeric" };
    const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedDate = new Intl.DateTimeFormat("en-US", dateOptions).format(
      date,
    );
    const formattedTime = new Intl.DateTimeFormat("en-US", timeOptions).format(
      date,
    );
    return `${formattedDate} • ${formattedTime}`;
  };

  /**
   * Loads the initial visit data for the user
   * @param {object} data - The user data containing visit information
   */
  const loadInitialVisit = async (data) => {
    if (data.visits.length > 0) {
      const nextVisit = data.visits[0];
      dispatch(
        setCurrentState({
          currentVisitId: nextVisit._id,
          currentTab:
            nextVisit.visitFinished !== undefined
              ? nextVisit.visitFinished
                ? "NOTES"
                : "RECORD"
              : "NOTES",
          currentNoteId: "",
        }),
      );
    } else {
      const newVisitId = generateId();
      const newVisit = {
        _id: newVisitId,
        visitName: "New Encounter",
        visitCreatedDate: formatDateTime(new Date()),
        visitLanguage: "ENGLISH",
        visitAudioTime: 0,
        visitTranscript: "",
        visitAdditionalPatientContext: "",
        visitFinished: false,
        visitTypeId: "",
        visitTypeUptoDate: true,
        visitCopyMetric: 0,
        visitDiagnosesState: "INCOMPLETE",
        notes: [],
        diagnoses: [],
      };
      dispatch(addVisit(newVisit));
      dispatch(
        upsertUserStats({
          userStatsDate: getCurrentDate(),
          userStatsVisitsCreated: 1,
        }),
      );
      dispatch(
        setCurrentState({
          currentVisitId: newVisitId,
          currentTab: "RECORD",
          currentNoteId: "",
        }),
      );
    }
  };

  /**
   * Renders the current frame based on the selected tab
   * @returns {JSX.Element} The current frame component
   */
  const renderCurrentFrame = () => {
    switch (currentTab) {
      case "RECORD":
        return (
          <FrameRecord key={currentVisitId} rootClassName="framerecord-root" />
        );
      case "TRANSCRIPT":
        return <FrameTranscript key={currentVisitId} />;
      case "NOTES":
        return (
          <FrameNotes key={currentVisitId} rootClassName="framenotes-root" />
        );
      case "TEMPLATES":
        return (
          <FrameTemplates
            key={currentVisitId}
            rootClassName="frametemplates-root"
          />
        );
      case "TCENTER":
        return (
          <FrameTCenter
            key={currentVisitId}
            rootClassName="frametcenter-root"
          />
        );
      case "DIAGNOSES":
        return <FrameDiagnoses key={currentVisitId} rootClassName="" />;
      default:
        return null;
    }
  };

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <div
      className={`dashboard-container ${currentDisplaySidebar ? "show-sidebar" : "show-frame"}`}
    >
      <Helmet>
        <title>Dashboard - Halo</title>
        <meta
          name="description"
          content="Halo's AI medical scribe enhances physician experience by eliminating patient charting drudgery, allowing doctors to focus on compassionate patient care."
        />
        <meta property="og:title" content="Dashboard - Halo" />
        <meta
          property="og:description"
          content="Halo's AI medical scribe enhances physician experience by eliminating patient charting drudgery, allowing doctors to focus on compassionate patient care."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/6c79c93a-b196-4a81-890c-58d62a680e8d/aff5fbcd-b493-49c9-91ba-3aaa09ff6ab6?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      {currentDisplaySidebar && (
        <Sidebar rootClassName="sidebar-root-class-name1" />
      )}
      <div
        className={`dashboard-container1 ${currentDisplaySidebar ? "dashboard-hide" : ""}`}
      >
        <TabBar />
        <div
          className={`dashboard-frame ${currentDisplaySidebar ? "dashboard-hide" : ""}`}
        >
          {renderCurrentFrame()}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
